/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'doc': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M754.32 71 269.678 71c-63.045 0-114.034 50.987-114.034 114.033l0 655.695c0 62.954 51.076 114.033 114.034 114.033l285.086 0 57.015 0 28.508 0 0-1.098c93.293-10.69 216.463-121.984 226.971-226.973l1.098 0L868.356 185.033C868.355 121.987 817.278 71 754.32 71L754.32 71zM626.033 897.743l-14.254 0L611.779 783.71l0-28.511c0-15.712 12.791-28.508 28.508-28.508l28.511 0 141.079 0C796.355 797.326 690.266 897.743 626.033 897.743L626.033 897.743zM811.335 669.677 597.524 669.677l0 2.011c-20.011 5.205-35.541 20.739-40.753 40.754l-2.007 0 0 14.25 0 171.052L269.678 897.744c-31.527 0-57.015-25.493-57.015-57.015L212.663 185.033c0-31.526 25.488-57.015 57.015-57.015L754.32 128.018c31.525 0 57.015 25.488 57.015 57.015L811.335 669.677 811.335 669.677zM447.856 527.133 333.822 527.133c-11.786 0-21.379 9.599-21.379 21.38 0 11.786 9.593 21.385 21.379 21.385l114.034 0c11.788 0 21.38-9.599 21.38-21.385C469.236 536.637 459.644 527.133 447.856 527.133L447.856 527.133zM533.38 527.133l-14.254 0c-11.784 0-21.38 9.599-21.38 21.38 0 11.786 9.596 21.385 21.38 21.385l14.254 0c11.786 0 21.385-9.599 21.385-21.385C554.765 536.637 545.166 527.133 533.38 527.133L533.38 527.133zM683.052 384.59 340.947 384.59c-15.713 0-28.504 12.796-28.504 28.509 0 15.717 12.791 28.508 28.504 28.508l342.105 0c15.714 0 28.506-12.791 28.506-28.508C711.558 397.293 698.766 384.59 683.052 384.59L683.052 384.59zM683.052 242.052 340.947 242.052c-15.713 0-28.504 12.792-28.504 28.505 0 15.717 12.791 28.509 28.504 28.509l342.105 0c15.714 0 28.506-12.792 28.506-28.509C711.558 254.844 698.766 242.052 683.052 242.052L683.052 242.052zM683.052 242.052" p-id="5337"></path>'
  }
})
