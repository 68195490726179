/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'aliyun': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M952.74 225.925c26.747 152.33 26.747 416.86 0 572.283-18.524 110.131-42.179 135.864-154.327 155.423-154.327 24.699-414.628 26.757-572.048-1.035-111.114-19.558-134.778-42.199-154.327-154.388-27.78-153.364-27.78-415.826 0-572.283 19.549-110.131 42.179-134.83 154.327-154.388 153.293-27.792 416.687-26.768 572.048 1.024C909.527 92.119 933.192 114.77 952.74 225.925z m-584.397-54.548c-49.387 0-90.542 40.14-90.542 89.548 0 50.432 41.155 90.573 90.542 90.573 16.466 0 31.898-4.116 45.271-12.35 94.659-54.548 102.881-56.606 198.574 1.035 13.374 7.209 28.805 12.35 45.271 12.35 49.388 0 89.508-40.141 89.508-90.573 0-49.408-39.096-90.573-89.508-90.573-16.466 0-31.897 5.15-45.27 12.35-97.742 55.582-100.824 55.582-198.575 0-13.373-8.244-28.805-12.36-45.271-12.36z m433.152 431.267c49.388 0 89.508-40.14 89.508-90.572 0-49.408-40.12-89.55-89.508-89.55-16.466 0-31.898 4.117-45.271 11.326-95.683 54.549-132.72 46.316-211.948 13.384l-12.349-4.116c-6.175-2.059-12.35-3.093-19.548-3.093-5.14 0-11.315 1.034-18.524 2.058l-12.35 5.151c-84.367 32.942-115.23 42.199-212.971-14.408-13.374-7.209-28.805-11.325-44.237-11.325-50.412 0-90.542 40.14-90.542 89.549 0 50.432 40.13 90.572 89.508 90.572 16.466 0 31.897-4.116 45.27-12.349 90.543-52.49 138.896-48.374 157.42-31.908 7.199 4.117 1.024 10.291-14.407 29.85-23.665 27.791-73.053 67.932-117.29 109.107-18.524 17.5-27.78 39.117-27.78 64.84 0 50.432 40.13 90.572 89.507 90.572 11.316 0 22.63-2.058 33.956-5.15 86.426-23.675 156.385-64.84 201.657-86.457 12.349-7.209 5.14 3.093 17.49 32.932 8.232 22.64 34.98 56.607 74.076 59.7 59.678 6.174 92.6-45.292 79.226-93.666-16.465-58.665-76.134-100.874-138.895-76.165-66.877 27.791-126.546 41.175-192.4 41.175-12.349-1.034-11.314-5.15-8.232-12.35 8.233-29.849 58.644-115.281 117.289-144.097 59.678-27.791 110.09-38.082 215.03 22.641 13.393 7.199 28.825 12.35 46.315 12.35z" p-id="6251"></path>'
  }
})