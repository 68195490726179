/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  '404': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M121.7 73.3v10c4-7.7 6.2-16.1 6.2-25C128 26 99.3 0 64 0S0 26 0 58.2v1.2l23-26h13.3L16.6 60.8H23v-7l13.7-19.4v49.4H23V73.3H2.2a61.6 61.6 0 0 0 46 41.4c-1.5 3.3-5.7 11.2-12.6 12.6-8.6 1.8 23.3.5 46.2-13.1a63 63 0 0 0 39.7-30.5H108V73.3H85V59.5l23-26h13l-19.4 27.2h6.4v-7.5l13.7-19.4v39.5zM43.5 76a10.5 10.5 0 0 1-1-4.5v-27c0-1.7.3-3.2 1-4.6a11.7 11.7 0 0 1 2.7-3.7 13 13 0 0 1 9-3.3h11.3a13.6 13.6 0 0 1 9 3.3L63.2 52.6v-3a2 2 0 0 0-.7-1.4c-.4-.4-1-.6-1.6-.6-.7 0-1.2.2-1.7.6a2 2 0 0 0-.6 1.5v9l-14.2 19a10.6 10.6 0 0 1-1-1.6zm35.7-4.5c0 1.6-.3 3-1 4.5a11.7 11.7 0 0 1-2.7 3.7 13 13 0 0 1-9 3.4H55.2a13.6 13.6 0 0 1-9-3.4 12.5 12.5 0 0 1-1.4-1.5L58.5 60v6.4c0 .6.2 1.1.7 1.5.4.4 1 .6 1.6.6.7 0 1.2-.2 1.7-.6a2 2 0 0 0 .7-1.5V54L76 37a10.5 10.5 0 0 1 3.2 7.7v27z"/>'
  }
})
