/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'addNode': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: `<path d="M950.857143 1024H365.714286a73.142857 73.142857 0 0 1-73.142857-73.142857V585.142857H146.285714a73.142857 73.142857 0 0 1-73.142857-73.142857V146.285714a73.142857 73.142857 0 0 1 0-146.285714h512a73.142857 73.142857 0 0 1 0 146.285714H219.428571v292.571429h73.142858V365.714286a73.142857 73.142857 0 0 1 73.142857-73.142857h585.142857a73.142857 73.142857 0 0 1 73.142857 73.142857v585.142857a73.142857 73.142857 0 0 1-73.142857 73.142857z m-146.285714-438.857143h-73.142858V512a73.142857 73.142857 0 0 0-146.285714 0v73.142857H512a73.142857 73.142857 0 0 0 0 146.285714h73.142857v73.142858a73.142857 73.142857 0 0 0 146.285714 0v-73.142858h73.142858a73.142857 73.142857 0 0 0 0-146.285714z" fill="#0078D7" p-id="6979"></path>`
  }
})
