/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'exit-fullscreen': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M49.2 41.3l-.1-35.2c0-2.7-2.3-4.4-5-4.4h-3.7a4.8 4.8 0 0 0-4.8 5l.2 19.2L11.6 2a6.7 6.7 0 0 0-9.5 0 6.8 6.8 0 0 0 0 9.5l24 23.7H7.6A5.5 5.5 0 0 0 2 40.5V44c0 2.7 2.3 5 5 5l35-.2h2.6a4.6 4.6 0 0 0 3.4-1.3c1-.9 1.2-2.1 1.2-3.5l-.3-2.4.2-.2zm52.5 51.2h18.4c2.7 0 5.2-1.6 5.6-4.8v-3.5c0-2.7-2.3-5-5-5l-34.6.2H86l-2.5-.1a4.6 4.6 0 0 0-3.4 1.4c-1 .8-1.2 2-1.2 3.4l.3 2.5-.2.1.1 34.7c0 2.7 2.3 4.4 5 4.4h3.5c2.7 0 4.9-2.3 4.8-5l-.2-18.8 24.2 24a6.7 6.7 0 0 0 9.5 0 6.7 6.7 0 0 0 0-9.5l-24.2-24zM48.1 80.7a4.6 4.6 0 0 0-3.4-1.4h-2.6l-35-.1c-2.7 0-5 2.3-5 5v3.5c.4 3.2 2.9 4.8 5.6 4.8h18.5l-24.1 24a6.8 6.8 0 0 0 0 9.5 6.7 6.7 0 0 0 9.5 0l24.2-23.8-.2 18.9c0 2.7 2 5 4.8 5H44c2.8 0 5-1.7 5-4.4l.2-35-.2-.1.3-2.5c0-1.3-.3-2.6-1.2-3.4zm32-33.3a4.6 4.6 0 0 0 3.4 1.4H86l.1-.1 35.1.2c2.7 0 5-2.3 5-5v-3.5c-.4-3.2-3-5-5.6-5H102l23.9-23.8a6.7 6.7 0 0 0 0-9.5 6.7 6.7 0 0 0-9.5 0L92.3 26l.1-19.4c0-2.7-2-5-4.8-5h-3.4c-2.8 0-5 1.7-5 4.4L79 41.3l.2.2-.3 2.4c0 1.4.3 2.6 1.2 3.5z"/>'
  }
})
