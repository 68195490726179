/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'refund': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M736 448 288 448c-19.2 0-32 12.8-32 32s12.8 32 32 32l192 0 0 96-160 0c-19.2 0-32 12.8-32 32s12.8 32 32 32l160 0 0 96c0 19.2 12.8 32 32 32s32-12.8 32-32l0-96 160 0c19.2 0 32-12.8 32-32s-12.8-32-32-32l-160 0 0-96 192 0c19.2 0 32-12.8 32-32S755.2 448 736 448z" p-id="12224"></path><path d="M489.6 406.4c6.4 6.4 16 9.6 22.4 9.6s16-3.2 22.4-9.6l128-128c12.8-12.8 12.8-32 0-44.8s-32-12.8-44.8 0L512 339.2l-105.6-105.6c-12.8-12.8-32-12.8-44.8 0s-12.8 32 0 44.8L489.6 406.4z" p-id="12225"></path><path d="M512 64C352 64 208 147.2 128 281.6L128 256c0-19.2-12.8-32-32-32S64 236.8 64 256l0 128c0 19.2 12.8 32 32 32l128 0c19.2 0 32-12.8 32-32s-12.8-32-32-32L163.2 352c64-137.6 198.4-224 348.8-224 211.2 0 384 172.8 384 384s-172.8 384-384 384c-153.6 0-294.4-92.8-352-233.6-6.4-16-25.6-22.4-41.6-16-16 6.4-22.4 25.6-16 41.6C169.6 854.4 332.8 960 512 960c246.4 0 448-201.6 448-448S758.4 64 512 64z" p-id="12226"></path>'
  }
})