/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'protocol': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M64 132v760h896V132H64z m846 710H114V182h796v660zM351.8 363h-50v-50h50v50z m123.5 0h-50v-50h50v50z m123.4 0h-50v-50h50v50z m123.5 0h-50v-50h50v50zM807 513.8H217v-50h590v50z m-271.6 147H217v-50h318.4v50z" p-id="1188"></path>'
  }
})