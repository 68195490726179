/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'docYes': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M433.385909 921.340102c28.496569 0 46.95066 24.19135 46.95066 52.450111 0 28.258761-18.454091 49.884914-46.95066 49.884914H103.520162C46.527025 1023.675127 0.324873 977.857624 0.324873 921.340102V102.659898C0.324873 46.143675 46.527025 0.324873 103.518863 0.324873H774.289543C831.280081 0.324873 877.482234 46.142376 877.482234 102.659898v277.698924c0 28.258761-23.921056 48.854416-52.417625 48.854417-28.496569 0-50.776365-20.596954-50.776365-48.854417V102.659898H103.520162v818.677605h329.865747z m334.450355 102.335025C626.542457 1023.675127 512 909.13267 512 767.837563S626.542457 512 767.837563 512 1023.675127 626.542457 1023.675127 767.837563 909.13267 1023.675127 767.837563 1023.675127z m0-73.096447c100.926376 0 182.742416-81.816041 182.742416-182.741117 0-100.925076-81.81734-182.741117-182.742416-182.741116-100.923777 0-182.739817 81.816041-182.739817 182.741116 0 100.925076 81.816041 182.741117 182.741116 182.741117z m139.617462-284.729177c-11.117157-10.208812-29.143716-10.208812-40.260873 0L744.331046 778.655838 706.793909 744.190701c-11.118457-10.207513-29.143716-10.207513-40.263472 0-11.117157 10.208812-11.117157 26.759147 0 36.967959l57.667573 52.947817c5.559228 5.103107 12.845482 7.65401 20.131736 7.65401 7.286254 0 14.572508-2.550904 20.131736-7.65401a27.386802 27.386802 0 0 0 2.136366-2.202639 28.095025 28.095025 0 0 0 2.392365-1.957036l138.463513-127.13064c11.118457-10.207513 11.118457-26.759147 0-36.966659zM219.614213 345.846904c0-36.675574 25.389482-53.136244 54.366863-53.136244h258.040203c28.97868 0 58.575919 16.46067 58.575919 53.136244s-29.598538 53.136244-58.575919 53.136243H273.979777c-28.976081 0-54.365563-16.46067-54.365564-53.136243z m0 288.962923c0-37.270741 22.346071-49.713381 48.192975-49.71338h123.87671c25.845604 0 46.094294 12.44264 46.094295 49.71338 0 37.272041-20.247391 49.713381-46.092995 49.713381h-123.87801c-25.845604 0-48.192975-12.44134-48.192975-49.713381z"  p-id="2986"></path>'
  }
})