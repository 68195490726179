/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'department': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M608 428.8H416c-51.2 0-96-44.8-96-96v-192c0-51.2 44.8-96 96-96h192c51.2 0 96 44.8 96 96v192c0 57.6-44.8 96-96 96z m-192-320c-19.2 0-32 12.8-32 32v192c0 19.2 12.8 32 32 32h192c19.2 0 32-12.8 32-32v-192c0-19.2-12.8-32-32-32H416z m-192 864h-64c-51.2 0-96-44.8-96-96v-64c0-51.2 44.8-96 96-96h64c51.2 0 96 44.8 96 96v64c0 57.6-44.8 96-96 96z m-64-192c-19.2 0-32 12.8-32 32v64c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-64c0-19.2-12.8-32-32-32h-64z m704 192h-64c-51.2 0-96-44.8-96-96v-64c0-51.2 44.8-96 96-96h64c51.2 0 96 44.8 96 96v64c0 57.6-44.8 96-96 96z m-64-192c-19.2 0-32 12.8-32 32v64c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-64c0-19.2-12.8-32-32-32h-64z m-256 192h-64c-51.2 0-96-44.8-96-96v-64c0-51.2 44.8-96 96-96h64c51.2 0 96 44.8 96 96v64c0 57.6-44.8 96-96 96z m-64-192c-19.2 0-32 12.8-32 32v64c0 19.2 12.8 32 32 32h64c19.2 0 32-12.8 32-32v-64c0-19.2-12.8-32-32-32h-64z" p-id="5552"></path><path d="M224 780.8h-64v-128c0-51.2 44.8-96 96-96h512c51.2 0 96 44.8 96 96v128h-64v-128c0-19.2-12.8-32-32-32H256c-19.2 0-32 12.8-32 32v128z" p-id="5553"></path><path d="M480 396.8h64v320h-64z" p-id="5554"></path>'
  }
})

