/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'card': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M1053.926 141.211h-798.626c-62.983 0.064-114.023 51.105-114.087 114.082v513.408c0.064 62.983 51.105 114.023 114.083 114.087h798.632c62.983-0.064 114.024-51.105 114.087-114.082v-513.408c-0.064-62.983-51.105-114.023-114.082-114.087zM255.298 198.25h798.627c31.487 0.047 56.998 25.562 57.039 57.045v114.093h-912.704v-114.087c0.040-31.488 25.553-57.003 57.035-57.049zM1053.926 825.75h-798.626c-31.487-0.047-56.998-25.562-57.038-57.045v-342.267h912.704v342.264c-0.041 31.488-25.553 57.002-57.035 57.050z" p-id="5808" data-spm-anchor-id="a313x.7781069.0.i13" class=""></path><path d="M545.535 498.651c-1.495-0.584-3.226-0.921-5.035-0.921-6.068 0-11.249 3.802-13.292 9.151l-72.248 192.684-72.226-192.577c-2.117-5.389-7.274-9.133-13.307-9.133-7.876 0-14.259 6.384-14.259 14.259 0 1.761 0.319 3.447 0.903 5.003l85.531 228.078c2.082 5.454 7.271 9.259 13.349 9.259s11.266-3.803 13.316-9.16l85.597-228.274c0.586-1.497 0.924-3.232 0.924-5.045 0-6.069-3.802-11.252-9.155-13.293zM654.613 497.738c-0.004 0-0.007 0-0.009 0-7.871 0-14.253 6.381-14.253 14.253 0 0.005 0 0.007 0 0.010v228.175c0.003 7.876 6.387 14.258 14.262 14.258s14.259-6.384 14.262-14.258v-228.176c0-0.004 0-0.007 0-0.009 0-7.871-6.381-14.253-14.253-14.253-0.005 0-0.007 0-0.010 0zM882.789 497.738h-114.087c-0.004 0-0.007 0-0.009 0-7.871 0-14.252 6.381-14.252 14.253 0 0.005 0 0.007 0 0.010v228.175c0 7.877 6.385 14.262 14.262 14.262s14.262-6.385 14.262-14.262v-99.825h99.825c39.382 0 71.306-31.925 71.306-71.306s-31.925-71.306-71.306-71.306zM882.789 611.825h-99.825v-85.564h99.825c0.197-0.005 0.428-0.007 0.663-0.007 23.632 0 42.787 19.157 42.787 42.787 0 23.632-19.157 42.787-42.787 42.787-0.232 0-0.466-0.003-0.697-0.007z" p-id="5809"></path>'
  }
})