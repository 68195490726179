/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'payrollAdd': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M 445.6 371.5 c 14.5 0 26.2 -11.8 26.2 -26.2 v -8.9 c 0 -14.5 -11.8 -26.2 -26.2 -26.2 h -38.8 l 59.8 -84.1 c 5.4 -8 6 -18.2 1.5 -26.7 c -4.5 -8.7 -13.4 -14.1 -23.2 -14.1 H 431 c -9 0 -17.3 4.5 -22.2 12.2 l -60.2 89.1 l -59.4 -88.9 c -4.8 -7.7 -13.1 -12.4 -22.2 -12.4 h -16.9 c -9.8 0 -18.7 5.4 -23.3 14.1 c -4.4 8.5 -3.9 18.7 1.5 26.7 l 59.1 84.2 h -40.2 c -14.5 0 -26.2 11.8 -26.2 26.2 v 8.9 c 0 14.5 11.8 26.2 26.2 26.2 h 66.7 v 9.3 h -66.7 c -14.5 0 -26.2 11.8 -26.2 26.2 v 9.1 c 0 14.5 11.8 26.2 26.2 26.2 h 66.7 V 487 c 0 14.5 11.8 26.2 26.2 26.2 H 355 c 14.5 0 26.2 -11.8 26.2 -26.2 v -44.6 h 64.4 c 14.5 0 26.2 -11.8 26.2 -26.2 v -9.1 c 0 -14.5 -11.8 -26.2 -26.2 -26.2 h -64.4 v -9.3 h 64.4 Z M 564 219.9 c -17.7 0 -32 14.3 -32 32 s 14.3 32 32 32 h 200 c 17.7 0 32 -14.3 32 -32 s -14.3 -32 -32 -32 H 564 Z M 694 415.9 c 17.7 0 32 -14.3 32 -32 s -14.3 -32 -32 -32 H 564 c -17.7 0 -32 14.3 -32 32 s 14.3 32 32 32 h 130 Z" p-id="1519"></path><path d="M 162 895.9 V 128.1 l 0.1 -0.1 h 701.8 l 0.1 0.1 v 281.6 c 6.6 -3 13.5 -4.5 20.5 -4.5 c 12.6 0 24.6 5 34.7 14.3 l 8.9 8.2 V 128 c 0 -35.2 -28.8 -64 -64 -64 H 162 c -35.2 0 -64 28.8 -64 64 v 768 c 0 35.2 28.8 64 64 64 h 316.7 c -0.1 -0.9 -0.1 -1.8 0 -2.7 v -0.4 l 0.1 -0.7 l 8.4 -60.2 h -325 c -0.1 0 -0.2 -0.1 -0.2 -0.1 Z" p-id="1520"></path><path d="M 943.7 511.5 l -34.6 -31.9 c -10.1 -9.4 -22.1 -14.3 -34.7 -14.3 c -14.8 0 -29.4 7 -41.1 19.7 L 561.4 774 l -14.7 105.3 l -0.1 0.7 v 0.4 c -0.3 6.2 2.1 12.1 6.7 16.4 c 3.9 3.6 8.9 5.5 14.1 5.5 c 0.8 0 1.6 0 2.4 -0.1 h 0.4 l 105.1 -22.7 L 945.4 592 c 10.6 -10.7 16.5 -25.5 16.1 -40.5 c -0.1 -15.2 -6.7 -29.7 -17.8 -40 Z M 647.1 827.3 l -32.9 3.6 l 1.2 -32.7 L 821.7 580 l 31.5 29.2 l -206.1 218.1 Z m 258.4 -274.6 l -14.1 15.2 l -31.4 -29.1 l 14 -15.2 h 0.3 l 31.2 28.8 c 0.1 0.1 0.1 0.2 0 0.3 Z" p-id="1521"></path>'
  }
})