/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cate': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M204.8 281.6H76.8c-42.24 0-76.8-34.56-76.8-76.8V76.8c0-42.24 34.56-76.8 76.8-76.8h128c42.24 0 76.8 34.56 76.8 76.8v128c0 42.24-34.56 76.8-76.8 76.8zM76.8 51.2c-14.08 0-25.6 11.52-25.6 25.6v128c0 14.08 11.52 25.6 25.6 25.6h128c14.08 0 25.6-11.52 25.6-25.6V76.8c0-14.08-11.52-25.6-25.6-25.6H76.8zM947.2 281.6H512c-42.24 0-76.8-34.56-76.8-76.8V76.8c0-42.24 34.56-76.8 76.8-76.8h435.2c42.24 0 76.8 34.56 76.8 76.8v128c0 42.24-34.56 76.8-76.8 76.8zM512 51.2c-14.08 0-25.6 11.52-25.6 25.6v128c0 14.08 11.52 25.6 25.6 25.6h435.2c14.08 0 25.6-11.52 25.6-25.6V76.8c0-14.08-11.52-25.6-25.6-25.6H512zM204.8 652.8H76.8c-42.24 0-76.8-34.56-76.8-76.8V448c0-42.24 34.56-76.8 76.8-76.8h128c42.24 0 76.8 34.56 76.8 76.8v128c0 42.24-34.56 76.8-76.8 76.8zM76.8 422.4c-14.08 0-25.6 11.52-25.6 25.6v128c0 14.08 11.52 25.6 25.6 25.6h128c14.08 0 25.6-11.52 25.6-25.6V448c0-14.08-11.52-25.6-25.6-25.6H76.8zM947.2 652.8H512c-42.24 0-76.8-34.56-76.8-76.8V448c0-42.24 34.56-76.8 76.8-76.8h435.2c42.24 0 76.8 34.56 76.8 76.8v128c0 42.24-34.56 76.8-76.8 76.8zM512 422.4c-14.08 0-25.6 11.52-25.6 25.6v128c0 14.08 11.52 25.6 25.6 25.6h435.2c14.08 0 25.6-11.52 25.6-25.6V448c0-14.08-11.52-25.6-25.6-25.6H512zM204.8 1024H76.8c-42.24 0-76.8-34.56-76.8-76.8V819.2c0-42.24 34.56-76.8 76.8-76.8h128c42.24 0 76.8 34.56 76.8 76.8v128c0 42.24-34.56 76.8-76.8 76.8zM76.8 793.6c-14.08 0-25.6 11.52-25.6 25.6v128c0 14.08 11.52 25.6 25.6 25.6h128c14.08 0 25.6-11.52 25.6-25.6V819.2c0-14.08-11.52-25.6-25.6-25.6H76.8zM947.2 1024H512c-42.24 0-76.8-34.56-76.8-76.8V819.2c0-42.24 34.56-76.8 76.8-76.8h435.2c42.24 0 76.8 34.56 76.8 76.8v128c0 42.24-34.56 76.8-76.8 76.8zM512 793.6c-14.08 0-25.6 11.52-25.6 25.6v128c0 14.08 11.52 25.6 25.6 25.6h435.2c14.08 0 25.6-11.52 25.6-25.6V819.2c0-14.08-11.52-25.6-25.6-25.6H512z" p-id="4574"></path>'
  }
})
