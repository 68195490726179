/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shuju': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M53.424552 953.37931c9.498483 0 17.196138 7.697655 17.196138 17.196138V35.310345a35.310345 35.310345 0 0 0-70.62069 0v935.265103A53.424552 53.424552 0 0 0 53.424552 1024H988.689655a35.310345 35.310345 0 0 0 0-70.62069H53.424552z m810.125241-90.747586a35.310345 35.310345 0 0 0 70.62069 0V107.272828a35.310345 35.310345 0 1 0-70.62069 0V862.631724z m-530.714483 0.247173a35.310345 35.310345 0 1 0 70.62069 0V322.913103a35.310345 35.310345 0 1 0-70.62069 0v539.965794zM232.518621 322.913103a35.310345 35.310345 0 1 0-70.62069 0v539.965794a35.310345 35.310345 0 1 0 70.62069 0V322.913103z m442.103172 539.806897a35.310345 35.310345 0 1 0 70.62069 0V502.995862a35.310345 35.310345 0 1 0-70.62069 0v359.724138z m-100.281379-359.724138a35.310345 35.310345 0 1 0-70.62069 0v359.724138a35.310345 35.310345 0 1 0 70.62069 0V502.995862z" p-id="3176"></path>'
  }
})