/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shopping': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M43 101.4c1.6 0 3.1.3 4.6 1a12.3 12.3 0 0 1 3.9 2.7c1 1.2 2 2.6 2.6 4.2a14.4 14.4 0 0 1-2.6 14.5 13.3 13.3 0 0 1-4 2.8 10.6 10.6 0 0 1-4.6 1c-1.7 0-3.3-.3-4.7-1a13.6 13.6 0 0 1-3.8-2.8c-1.2-1.2-2-2.6-2.6-4.2a14.4 14.4 0 0 1-1-5.2c0-1.8.4-3.5 1-5 .6-1.7 1.4-3 2.6-4.3a12.5 12.5 0 0 1 3.8-2.7c1.4-.7 3-1 4.7-1zm53.8.2c1.7 0 3.3.4 4.8 1a11.4 11.4 0 0 1 3.9 2.8 13.8 13.8 0 0 1 2.6 14.4c-.7 1.7-1.6 3-2.6 4.2a12.3 12.3 0 0 1-4 2.9 11 11 0 0 1-4.7 1 10.6 10.6 0 0 1-4.6-1 12.5 12.5 0 0 1-3.8-2.9c-1.1-1.1-2-2.5-2.6-4.2a13.6 13.6 0 0 1-1-5 13.6 13.6 0 0 1 3.6-9.4 11.6 11.6 0 0 1 3.8-2.8 11.2 11.2 0 0 1 4.6-1zM118.6 21c2.4 0 4.3.4 5.7 1 1.3.8 2.3 1.7 2.8 2.7a6.4 6.4 0 0 1 .8 3.3c0 1.2-.2 2.2-.5 3l-1.6 5.4A589.3 589.3 0 0 1 123 45a1236.4 1236.4 0 0 0-3 9.4l-2.3 7.4a16.4 16.4 0 0 1-4.3 8 9.5 9.5 0 0 1-6.3 2.1H39l2 12.8h65.3c4.2 0 6.2 2 6.2 5.9 0 1.9-.4 3.5-1.2 4.9-.8 1.3-2.4 2-4.9 2H38.5c-1.7 0-3.2-.4-4.3-1.3-1.2-.8-2.2-2-3-3.3a21.3 21.3 0 0 1-1.8-4.5 44.1 44.1 0 0 1-1.1-4.5A233.5 233.5 0 0 0 26 71.6l-1.9-11a6273.2 6273.2 0 0 1-7.6-44.1H6.9a5 5 0 0 1-3.3-1 9 9 0 0 1-2.1-2.6A10.4 10.4 0 0 1 .3 9.7 17 17 0 0 1 0 6.5c0-1.9.6-3.4 1.8-4.7A6.2 6.2 0 0 1 6.5 0h13c1.8 0 3.2.3 4.2.9 1 .5 1.9 1.2 2.5 2a8.5 8.5 0 0 1 1.3 2.8L28 8l.6 3.2a1032.4 1032.4 0 0 1 1.2 9.6h88.7z"/>'
  }
})
