/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'order': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M673.9 934.1H350.1c-118 0-214.1-96-214.1-214.1V304c0-118.1 96.1-214.1 214.1-214.1h323.8C791.9 89.9 888 186 888 304v416c0 118.1-96.1 214.1-214.1 214.1z m-323.8-769c-76.6 0-138.9 62.3-138.9 138.9v416c0 76.6 62.3 138.9 138.9 138.9h323.8c76.6 0 138.9-62.3 138.9-138.9V304c0-76.6-62.3-138.9-138.9-138.9H350.1z" p-id="11698"></path><path d="M558.2 288.1h-92.3c-54.7 0-99.1-44.5-99.1-99.1s44.5-99.1 99.1-99.1h92.3c54.7 0 99.1 44.5 99.1 99.1s-44.5 99.1-99.1 99.1z m-92.3-123c-13 0-23.9 11-23.9 23.9 0 13 11 23.9 23.9 23.9h92.3c13 0 23.9-11 23.9-23.9 0-13-11-23.9-23.9-23.9h-92.3z" p-id="11699"></path><path d="M473.6 777.6L346.4 650.4c-14.7-14.7-14.7-38.5 0-53.2 14.7-14.7 38.5-14.7 53.2 0l73.9 73.8 150.8-151.8c14.7-14.7 38.4-14.8 53.2-0.2 14.7 14.6 14.8 38.4 0.2 53.2L473.6 777.6z" fill="#2BA8FC" p-id="11700"></path>'
  }
})