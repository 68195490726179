/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'organization': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M959.884171 640.029318h-127.976012v-95.971348a64.030651 64.030651 0 0 0-64.009329-64.009329H543.95147v-95.971348h95.971348a64.009328 64.009328 0 0 0 63.988006-64.009328V64.11594a64.009328 64.009328 0 0 0-63.988006-63.988007H383.970793a64.030651 64.030651 0 0 0-64.009328 63.988007v255.952025a64.030651 64.030651 0 0 0 64.009328 64.009328h95.971348v95.971348H255.973458a64.030651 64.030651 0 0 0-63.988006 64.009329v95.971348H64.00944a64.030651 64.030651 0 0 0-63.988007 63.988006v255.973348a64.030651 64.030651 0 0 0 63.988007 63.988006h255.952025a64.030651 64.030651 0 0 0 64.009328-63.988006V704.017324a64.030651 64.030651 0 0 0-64.009328-63.988006h-63.988007v-95.971348h511.925372v95.971348h-63.988006a64.030651 64.030651 0 0 0-63.988006 63.988006v255.973348a64.030651 64.030651 0 0 0 63.988006 63.988006h255.973347a64.030651 64.030651 0 0 0 63.988007-63.988006V704.017324a64.030651 64.030651 0 0 0-63.988007-63.988006z m-575.913378-319.961353V64.11594h255.952025v255.952025H383.970793z m-64.009328 383.949359v255.973348H64.00944V704.017324h255.952025z m639.922706 255.973348H703.910824V704.017324h255.973347v255.973348z" p-id="7714"></path>'
  }
})