/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'project': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M997.76 494.72l-133.12-76.8 128-72.32a32 32 0 0 0 0-55.68L704 123.52a32 32 0 0 0-32 0L512 213.76 346.88 118.4a32 32 0 0 0-32 0L26.24 284.8a32 32 0 0 0 0 55.68l133.76 76.8-128 72.32a32 32 0 0 0 0 55.68L192 635.52v114.56a32 32 0 0 0 16 27.52l288 166.4a32 32 0 0 0 32 0l288-166.4a32 32 0 1 0-32-55.68L512 880l-256-147.84v-59.52l64 39.04a32 32 0 0 0 32 0L512 620.8l166.4 96a32 32 0 0 0 32 0l106.24-64 181.12-105.6a32 32 0 0 0 0-55.68zM512 288l224 128L512 547.2l-224-128L384 362.88z m172.8-99.84l224 128-108.8 64L576 250.88z m-353.92-5.12L448 250.88l-91.52 53.12L224 380.16 106.24 312.32zM114.56 517.12l108.8-64L320 512l128 71.68-108.8 64z m579.2 135.04L576 583.68 700.16 512l99.84-57.6 117.12 67.84z"  p-id="10214"></path>'
  }
})