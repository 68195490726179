/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrowDown': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M957.142857 475.428571q0 30.285714-21.142857 51.428571l-372 372.571429q-22.285714 21.142857-52 21.142857-30.285714 0-51.428571-21.142857l-372-372.571429q-21.714286-20.571429-21.714286-51.428571 0-30.285714 21.714286-52l42.285714-42.857143q22.285714-21.142857 52-21.142857 30.285714 0 51.428571 21.142857l168 168 0-402.285714q0-29.714286 21.714286-51.428571t51.428571-21.714286l73.142857 0q29.714286 0 51.428571 21.714286t21.714286 51.428571l0 402.285714 168-168q21.142857-21.142857 51.428571-21.142857 29.714286 0 52 21.142857l42.857143 42.857143q21.142857 22.285714 21.142857 52z" p-id="20998"></path>'
  }
})
