/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'question': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M522.752 984.576c-258.56 0-469.504-210.432-469.504-469.504C53.248 256.512 263.68 46.08 522.752 46.08s469.504 210.432 469.504 469.504c0 258.56-210.944 468.992-469.504 468.992z m0-863.232c-217.088 0-394.24 176.64-394.24 394.24 0 217.088 176.64 394.24 394.24 394.24s394.24-176.64 394.24-394.24c-0.512-217.6-177.152-394.24-394.24-394.24z" fill="#8799a3" p-id="4607"></path><path d="M650.752 273.92c-35.84-26.112-80.384-38.912-134.144-38.912-40.96 0-75.776 9.216-103.936 27.648-43.52 27.648-67.072 74.752-70.656 140.288 0 2.048-0.512 3.584-0.512 5.632 0 27.136 22.016 49.152 49.152 49.152s49.152-22.016 49.152-49.152c0-19.968 7.168-40.96 18.432-59.904 11.776-18.432 35.328-26.112 62.976-26.112 28.16 0 49.152 7.68 59.904 22.528 10.752 14.848 15.872 31.744 15.872 50.176 0 15.872-6.144 30.72-15.872 43.52-5.632 8.192-12.8 15.36-20.992 21.504l-26.112 20.48c-25.6 20.48-41.984 37.888-48.128 53.76-6.144 15.36-10.24 32.768-11.264 73.216 0 26.624 21.504 48.128 48.128 48.128s48.128-21.504 48.128-48.128c0.512-19.456 2.048-22.528 4.608-31.744 4.608-14.336 13.824-27.136 27.648-37.888l25.6-19.968c25.6-20.48 43.008-36.864 52.224-49.664 15.36-21.504 23.04-47.616 23.04-79.36 0-50.688-17.92-89.6-53.248-115.2z" fill="#8799a3" p-id="4608"></path><path d="M522.752 720.384m-48.128 0a48.128 48.128 0 1 0 96.256 0 48.128 48.128 0 1 0-96.256 0Z" fill="#8799a3" p-id="4609"></path>'
  }
})