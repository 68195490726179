/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'editDoc': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M301.176471 963.764706H180.705882c-66.258824 0-120.470588-54.211765-120.470588-120.470588V120.470588C60.235294 54.211765 114.447059 0 180.705882 0h602.352942c66.258824 0 120.470588 54.211765 120.470588 120.470588v271.058824c0 18.070588-12.047059 30.117647-30.117647 30.117647s-30.117647-12.047059-30.117647-30.117647V120.470588c0-36.141176-24.094118-60.235294-60.235294-60.235294H180.705882c-36.141176 0-60.235294 24.094118-60.235294 60.235294v722.82353c0 36.141176 24.094118 60.235294 60.235294 60.235294h150.588236c18.070588 0 30.117647 12.047059 30.117647 30.117647s-12.047059 30.117647-30.117647 30.117647H301.176471zM271.058824 240.941176h421.647058c18.070588 0 30.117647 12.047059 30.117647 30.117648s-12.047059 30.117647-30.117647 30.117647h-421.647058c-18.070588 0-30.117647-12.047059-30.117648-30.117647s12.047059-30.117647 30.117648-30.117648z m0 180.705883h301.17647c18.070588 0 30.117647 12.047059 30.117647 30.117647s-12.047059 30.117647-30.117647 30.117647h-301.17647c-18.070588 0-30.117647-12.047059-30.117648-30.117647s12.047059-30.117647 30.117648-30.117647z m0 180.705882h180.705882c18.070588 0 30.117647 12.047059 30.117647 30.117647s-12.047059 30.117647-30.117647 30.117647h-180.705882c-18.070588 0-30.117647-12.047059-30.117648-30.117647s12.047059-30.117647 30.117648-30.117647z m325.270588 295.152941l289.129412-289.129411-42.164706-42.164706-289.129412 289.129411-6.02353 48.188236 48.188236-6.02353z m289.129412-373.458823l42.164705 42.164706c24.094118 24.094118 24.094118 60.235294 0 84.329411l-289.129411 289.129412c-12.047059 12.047059-24.094118 18.070588-36.141177 18.070588l-84.329412 6.02353c-18.070588 0-30.117647-12.047059-30.117647-24.094118v-6.023529l6.02353-84.329412c0-12.047059 6.023529-24.094118 18.070588-36.141176l289.129412-289.129412c24.094118-24.094118 60.235294-24.094118 84.329412 0z" p-id="13759"></path>'
  }
})
