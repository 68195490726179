/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'moneyBag': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path d="M396.524 274.46a138.216 138.216 0 0 0 24.833 8.668c38.272 9.367 46.12 58.79 12.53 78.91-167.091 100.08-314.9 379.413-245.817 429.357 123.412 89.222 577.51 80.379 652.88-15.736 56.407-71.931-89.37-334.851-245.344-419.614-32.831-17.841-29.602-64.682 5.386-78.119a139.925 139.925 0 0 0 8.228-3.465c27.323-12.547 48.996-33.196 62.595-58.374-27.911-5.706-57.464-2.597-84.492 9.814-19.51 8.96-36.21 22.028-49.225 38.246-17.878 22.274-52.574 22.274-70.451 0-13.017-16.218-29.715-29.287-49.226-38.246-27.028-12.411-56.58-15.52-84.492-9.814 13.599 25.178 35.272 45.827 62.595 58.374zM910.38 827.018C802.418 964.603 294.3 974.492 135.353 859.654c-126.854-91.65 6.51-377.091 188.437-525.938-45.945-31.797-77.93-79.286-89.733-133.167-4.118-18.796 5.062-37.97 22.524-47.045 61.489-31.955 135.195-34.295 199.725-4.682 16.66 7.645 32.112 17.138 46.124 28.235 14.012-11.097 29.464-20.59 46.125-28.235 64.53-29.613 138.235-27.273 199.724 4.682 17.462 9.075 26.643 28.25 22.525 47.045-10.267 46.863-35.8 88.89-72.505 119.964 169.305 130.17 300.212 394.19 212.081 506.504zM358.077 712.12c-16.14-10.82-21.549-34.541-12.081-52.985 9.468-18.444 30.227-24.625 46.367-13.806 90.163 60.439 183.034 60.439 283.098-0.742 16.5-10.088 37.03-2.981 45.858 15.872 8.828 18.854 2.61 42.316-13.89 52.404-120.442 73.64-238.388 73.64-349.352-0.743z" p-id="2495"></path>'
  }
})